* {
    box-sizing: border-box;
}

@font-face {
    font-family: "Helvetica-Regular";
    src: url("../fonts/Helvetica-Regular.woff2") format("woff2"),
        url("../fonts/Helvetica-Regular.woff") format("woff");
}

@font-face {
    font-family: "Helvetica-Bold";
    src: url("../fonts/Helvetica-Bold.woff2") format("woff2"),
        url("../fonts/Helvetica-Bold.woff") format("woff");
    font-weight: bold;
}

html,
body,
.root,
.root-router-host {
    height: 100%;
}

.root>div:not(.loader-container) {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "Helvetica-Regular", sans-serif;
    font-size: 16px;
    color: #3f3f3f;
    background-color: #eee;
    overflow-x: hidden;
    margin: 0 auto;
}

body.app-type--webview {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

a {
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    padding: 0;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

.container {
    max-width: 540px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.portraits-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 16px;

    @media screen and (max-width: 760px) {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    }
}

.portraits-item {
    border-radius: 12px;
    border: 1px solid #cdcdcd;
    overflow: hidden;
    position: relative;
    padding-top: 100%;

    button {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.btn-choose-head {
    width: 86px;
    height: 86px;
    border: solid 3px #05b0ff;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 20;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    span {
        font-size: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: solid 3px #05b0ff;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;    
    }
}

.choose-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 4%;
    row-gap: 20px;
    height: max-content;
}

.choose-item-container {
    // max-width: 25vh;
    width: 48%;
    position: relative;
    margin-bottom: 0;
    &::before {
        content: '';
        display: block;
        padding-top: 100%;
        width: 100%;
    }
}

.choose-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.active {
        border: 3px solid #05b0ff;
    }
}

.app-webview--ios {
    .start-page {
        @media all and (min-height: 720px) {
            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
            }
        }
    }
}

.pt2 {
    padding-top: 2px;
}

.start-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0;
    &-header {
        color: #fff;
        padding-top: 0;
        padding-bottom: 16px;

        @media all and (min-width: 460px) {
            padding-top: 34px;
            padding-bottom: 34px;
        }

        h2 {
            font-family: "Helvetica-Bold", sans-serif;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            line-height: 1.35;
        }
    }
    .step-container {
        margin-bottom: 0;
    }
}

.step-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;

    svg {
        width: 16px;
        fill: #868686;
        margin-right: 12px;
    }

    p {
        color: #868686;
    }
}

.templates-container {
    flex-shrink: 0;
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;

    .container {
        display: flex;
        align-items: center;
        overflow: auto;
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
    }

    button {
        flex-shrink: 0;
        overflow: auto;
        background-size: cover;
    }
}

.app-type--web {
    .templates-container .container {
        padding-bottom: 2px;
        &::-webkit-scrollbar {
            height: 8px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-track {
            height: 8px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #cdcdcd;
        }
    }
}

.btn-choice-gender {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;

    svg {
        position: relative;
        z-index: 2;
        width: 12px;
        flex-shrink: 0;
        margin-bottom: 4px;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
    }

    &-title {
        font-family: "Helvetica-Bold", sans-serif;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        z-index: 2;

        span {
            color: #fff;
        }
    }
}

.right-position {
    display: flex;
    justify-content: flex-end;
    position: relative;
    h2 {
        width: calc(100% - 84px);
        text-align: right;

        @media all and (max-width: 359px) {
            font-size: 15px;
        }
    }
}

.btn-switch-to-male {
    background-image: url("/assets/images/bodychooser/male.png");
    .btn-choice-gender-title {
        color: #05b0ff;
    }

    svg {
        fill: #05b0ff;
    }
}

.btn-switch-to-female {
    background-image: url("/assets/images/bodychooser/female.png");
    .btn-choice-gender-title {
        color: #fe9e9e;
    }

    svg {
        fill: #fe9e9e;
    }
}

.btn-choice-template {
    width: 80px;
    height: 80px;
    margin-left: 2px;

    &.active {
        box-shadow: inset 0 0 0 3px #05b0ff;
    }
}

.btn-toggle-styles {
    width: 80px;
    height: 80px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 0 6px 0 12px;
    text-align: left;
    background-color: #7ecd21;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.4;
    word-break: break-word;
}

.collage-container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 1;
    height: 500px;
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
    }
}

.footer-container {
    background-color: #fff;
    padding: 15px 0;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 56px;
    }
    h2 {
        text-align: left;
        color: #999;
        line-height: 1.25;
        max-width: 200px;
    }
    .btn-done {
        position: static;
        flex-shrink: 0;
        box-shadow: 0 0 25px 0 rgba(51, 51, 51, 0.3);
        width: 56px;
        height: 56px;
    }
}

.collage {
    width: 100%;
    height: 100%;
    // max-height: 78.8vh;
    // position: absolute;
    // top: 0;
    // left: 0;
    // object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.photolab {
    width: 150px;
    height: 150px;
    position: absolute;
    right: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.collage-text {
    text-align: center;
}

.btn-done {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #7ecd21;
    box-shadow: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 6px;
    right: 8px;
    z-index: 100;
    svg {
        width: 21px;
        fill: #fff;
    }
}   

/*loader*/
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    p {
        color: #868686;
    }
    &.android {
        flex-direction: column;
        p {
            margin-bottom: 14px;
        }
    }
}
.loader-ios {
    display: flex;
    margin-right: 24px;
    .item-loader {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        animation: item-loader .9s linear infinite;
        margin: 0 6px;
    }

    .first-item-loader {
        background-color: #1db8ff;
    }

    .second-item-loader {
        background-color: #ffd300;
        animation: item-loader .9s linear infinite;
        animation-delay: .3s;
    }

    .third-item-loader {
        background-color: #fc4700;
        animation: item-loader .9s linear infinite;
        animation-delay: .6s;
    }

    @keyframes item-loader {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.8, 1.8);
        }
    }
}

.loader-android {
    width: 200px;
    height: 2px;
    background-color: rgba(5, 176, 255, 0.2);
    position: relative;
    overflow: hidden;
    span {
        position: absolute;
        top: 0;
        height: 100%;
        width: 70px;
        background-color: #05b0ff;
        animation: line-loader .9s linear infinite;
    }
    @keyframes line-loader {
        0% {
            left: -70px;
        }

        100% {
            left: calc(100% + 70px);
        }
    }
}

/*error block*/
.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    p {
        color: #868686;
        text-align: center;
        margin-bottom: 16px;
    }
    button {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        height: 48px;
        flex-grow: 0;
        border-radius: 6px;
        text-transform: uppercase;
        background-color: rgb(5,176,255);
        margin: 0 auto;
        padding: 0 10px;
        display: block;
    }
}

// --

.native-ad-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;

    .loader-positioner {
        position: relative;
        width: 100%;
        min-height: 40px;
    }
}

// --

.mysecretidentity .btn-switch-to-female {
    background-image: url("/assets/images/bodychooser/female_mysecretidentity.png");
}

.mysecretidentity .btn-switch-to-male {
    background-image: url("/assets/images/bodychooser/male_mysecretidentity.png");
}

//
.btn-container {
    display: flex;
    justify-content: center;
}

.btn-upload {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .96px;
    max-width: 320px;
    width: 100%;
    height: 80px;
    border-radius: 20px;
    background-color: #4d5ef6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.-show-popup {
    overflow: hidden;
}

.sticker-pack-page {
    .container {
        position: relative;
    }
}

.sticker-pack-page-modal {
    .modal {
        position: relative;
    }

    .btn-close {
        svg {
            fill: #000;
        }
    }

    .btn-choose-head {
        left: 10px;
        top: 10px;
    }
}

.sticker-pack-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.sticker-pack-container-small {
    column-gap: 2%;
}

.sticker-pack-container-large {
    column-gap: 5%;
}

.sticker-pack {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #4d5ef6;
    border-radius: 8px;

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
    }
}

.sticker-pack-small {
    width: 18%;
}

.sticker-pack-large {
    width: 30%;
}

.btn-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    svg {
        width: 100%;
        height: 100%;
        fill: #fff;
    }
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
}

.modal {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 90%;
    max-width: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
}

.sticker {
    width: 200px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #4d5ef6;
    margin-bottom: 20px;

    img {
        width: 100%;
    }
}

.btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 10%;
    row-gap: 16px;
    width: 100%;

    button {
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: .96px;
        max-width: 320px;
        width: 45%;
        height: 40px;
        border-radius: 10px;
        background-color: #4d5ef6;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.choose-head-modal {
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        z-index: 30;
        overflow: auto;
    }

    .start-page {
        max-width: 480px;
        width: 100%;
        height: auto;
        padding: 0 10px 10px;
    }
}

.btn-container {
    margin-bottom: 16px;

    & label {
        display: flex;
        align-items: center;
    }

    & input {
        margin-left: 10px;
    }
}

.image-view-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

@media all and (max-width: 720px) {
    .image-view-container {
        width: 280px;
        height: 280px;
        margin: 100px auto 20px;
    }

    .photolab {
        width: 100px;
        height: 100px;    
    }

    .collage-container{
        height: auto;
    }
}
